import React from "react"
import { motion } from "framer-motion"

import Close from "../../images/close.svg"
import AltairLogoWhiteSVG from "../../images/altair-logo-white.svg"
import AltairLogoBlackSVG from "../../images/altair-logo-black.svg"

const Header = ({ aboutUsActive, closeAboutUs }) => (
  <header>
    <motion.img
      initial={{ opacity: 0, rotate: 0 }}
      animate={{
        opacity: aboutUsActive ? 1 : 0,
        rotate: aboutUsActive ? 0 : 60,
      }}
      transition={{
        duration: 0.35,
        delay: aboutUsActive ? 0.5 : 0,
        ease: "easeInOut",
      }}
      style={{ pointerEvents: aboutUsActive ? "initial" : "none" }}
      src={Close}
      alt="Close button"
      onClick={closeAboutUs}
      className="close-btn"
    />
    <a href="/">
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: aboutUsActive ? 0 : 1 }}
        transition={{ delay: aboutUsActive ? 0 : 0.5 }}
        src={AltairLogoWhiteSVG}
        alt="Altair Logo"
        id="AltairLogo"
        className="logo non-interactive"
      />
    </a>
  </header>
)

export default Header
